import isFunction from 'lodash-es/isFunction';

import { Component, OnInit } from '@angular/core';
import {
    IModuleInitialData, IModulePreInitialData
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { stringNullOrEmpty } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import { environment } from '../../../environments/environment';
import { FeaturesHelper } from '../../helpers/bw-compat/features-helper';
import {
    registerUserSettingsInfoC2C, registerUserSettingsInfoPe
} from '../../helpers/bw-compat/user-settings.bw-compat';
import { TestApi } from '../../helpers/test-api';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BrowserService } from '../../services/browser.service';
import { CalculationServiceC2C } from '../../services/calculation/calculation-c2c.service';
import { CalculationServicePE } from '../../services/calculation/calculation-pe.service';
import { ChangesService } from '../../services/changes.service';
import { CodeListServiceC2C } from '../../services/code-list-c2c.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { DataService } from '../../services/data.service';
import { DateTimeService } from '../../services/date-time.service';
import { DesignSettingsService } from '../../services/design-settings.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentService } from '../../services/document.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { GeneralErrorService } from '../../services/general-error.service';
import { GuidService } from '../../services/guid.service';
import { ImportService } from '../../services/import.service';
import { InitialDataService } from '../../services/initial-data.service';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { IntegrationsDocumentService } from '../../services/integrations-document.service';
import { IntegrationsNotificationService } from '../../services/integrations-notification.service';
import { LaunchDarklyService } from '../../services/launch-darkly.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { MarketingService } from '../../services/marketing.service';
import { MathService } from '../../services/math.service';
import { MenuService2d } from '../../services/menu-2d.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { ModulesService } from '../../services/modules.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { QueryService } from '../../services/query.service';
import { RegionOrderService } from '../../services/region-order.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { ReportService } from '../../services/report.service';
import { RoutingService } from '../../services/routing.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { SignalRService } from '../../services/signalr.service';
import { TooltipService } from '../../services/tooltip.service';
import { TourService } from '../../services/tour.service';
import { TranslationFormatServiceInjected } from '../../services/translation-format.service';
import { TrimbleConnectService } from '../../services/trimble-connect.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

const enum DisplayType {
    loading = 'Loading',
    generalError = 'GeneralError',
    maintenancePage = 'MaintenancePage',
    initialized = 'Initialized',
    initializedNoData = 'InitializedNoData',
}

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
    public environment = environment;
    private loadedInternal = false;

    constructor(
        // Common
        public localizationService: LocalizationService,
        public modalService: ModalService,
        public mathService: MathService,
        public unitService: UnitService,
        public numberService: NumberService,
        public userService: UserService,
        public userSettingsService: UserSettingsService,
        public featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        public translationFormatService: TranslationFormatServiceInjected,
        public offlineService: OfflineService,
        public browserService: BrowserService,
        public apiService: ApiService,
        public licenseService: LicenseService,
        public signalRService: SignalRService,
        public designTemplateService: DesignTemplateService,
        public guidService: GuidService,
        public routingService: RoutingService,
        public tourService: TourService,
        public tooltipService: TooltipService,
        public commonCodeListService: CommonCodeListService,
        public queryService: QueryService,
        public importService: ImportService,
        public trimbleConnectService: TrimbleConnectService,
        public dateTimeService: DateTimeService,
        public reportTemplateService: ReportTemplateService,
        public productInformationService: ProductInformationService,
        public marketingService: MarketingService,
        public menuService: MenuService,
        public menu2dService: MenuService2d,
        public authenticationService: AuthenticationService,
        public favoritesService: FavoritesService,
        public featureVisibilityService: FeatureVisibilityService,
        public regionOrderService: RegionOrderService,
        public commonTrackingService: CommonTrackingService,
        public designSettingsService: DesignSettingsService,
        public sessionStorageService: SessionStorageService,
        // PE
        public codeListService: CodeListService,
        public calculationService: CalculationServicePE,
        public integrationsDataService: IntegrationsDataService,
        public integrationsDocumentService: IntegrationsDocumentService,
        public integrationsNotificationService: IntegrationsNotificationService,
        public initialDataService: InitialDataService,
        // C2C
        public codeListServiceC2C: CodeListServiceC2C,
        /**
         * @deprecated backwards compatibility, removed with C2C-11273
         */
        public calculationServiceC2C: CalculationServiceC2C,
        public reportService: ReportService,
        // CW
        public loggerService: LoggerService,
        public changesService: ChangesService,
        // Other
        private readonly generalErrorService: GeneralErrorService,
        private readonly dataService: DataService,
        private readonly modulesService: ModulesService,
        private readonly documentServiceCommon: DocumentService,
        public launchDarklyService: LaunchDarklyService
    ) { }

    public get errorTitle1() {
        return this.localizationService.getString('Agito.Hilti.Profis3.GeneralError.Title1', {
            tags: LocalizationService.PBrB,
            defaultString: 'SORRY, THERE IS AN ERROR'
        });
    }

    public get errorMessage() {
        return this.localizationService.getString('Agito.Hilti.Profis3.GeneralError.Content', {
            tags: LocalizationService.PBrB,
            defaultString: '<p>It appears a critical error appeared on our side. We apologize and ensure you that we are working on it. We will be back as soon as possible. Therefore please try again in a moment or contact us in case you have any questions or need help.<br>profis@hilti.com</p>'
        });
    }

    public get logOutButtonText() {
        return this.localizationService.getString('Agito.Hilti.Profis3.Main.UserOptions.Logout', {
            tags: LocalizationService.PBrB,
            defaultString: 'Logout'
        });
    }

    public get documentService() {
        // Temporary fix for the document service until we remove document service v1 and v2
        // BUDQBP-37682 remove code after cleanup
        return (this.documentServiceCommon as any)['documentService'] ?? this.documentServiceCommon;
    }

    public get displayType() {
        if (this.isLoadedNoData) {
            return DisplayType.initializedNoData;
        }

        if (this.isLoading) {
            return DisplayType.loading;
        }

        if (this.showErrorGeneral) {
            return DisplayType.generalError;
        }

        if (this.showMaintenancePage) {
            return DisplayType.maintenancePage;
        }

        return DisplayType.initialized;
    }

    private get showErrorGeneral() {
        return this.generalErrorService.showErrorGeneral;
    }

    private get showMaintenancePage() {
        return this.launchDarklyService.isInitialized && this.featureVisibilityService.isFeatureEnabled('MaintenancePage');
    }

    private get isLoadedNoData() {
        return this.isLoading && this.dataService.initialDataNotNeeded;
    }

    private get isLoading() {
        return !this.showErrorGeneral && !this.showMaintenancePage && !this.loaded;
    }

    private get loaded() {
        if (this.loadedInternal) {
            return true;
        }

        this.loadedInternal = this.localizationService.translationsLoaded && (!this.userService.isAuthenticated || this.dataService.initialDataLoaded);
        return this.loadedInternal;
    }

    public ngOnInit(): void {
        // init Test API
        TestApi.init();

        // application title
        document.title = environment.solutionName;

        // Google Tag Manager
        // the GTM code (ID) differs between the environments
        if (environment.useGoogleTagManager) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${environment.gtmCode}');`;
            document.head.appendChild(script);
        }
    }

    /**
     * Updates necessary module data on update event
     * @param updateData
     */
    public onModuleUpdate(updateData: IModuleInitialData) {
        if (updateData.quickStartApplications)
            this.modulesService.updateQuickStartApplications(updateData.quickStartApplications);

        if (updateData.appSettingsData)
            this.modulesService.updateAppSettingsData(updateData.appSettingsData);

        if (updateData.designInfo)
            this.modulesService.updateDesignInfo(updateData.designInfo);

        if (updateData.designListInfo)
            this.modulesService.updateDesignListInfo(updateData.designListInfo);
    }

    public onModuleInit(initialData: IModuleInitialData) {
        if (initialData.designTypes?.length) {
            this.modulesService.addDesignTypes(initialData.designTypes);
        }

        if (initialData.appSettingsData) {
            this.modulesService.addAppSettingsData(initialData.appSettingsData);
        }

        if (initialData.quickStartApplications) {
            this.modulesService.addQuickStartApplications(initialData.quickStartApplications);
        }

        if (initialData.virtualTours) {
            this.modulesService.addVirtualTours(initialData.virtualTours);
        }

        if (initialData.designInfo) {
            this.modulesService.addDesignInfo(initialData.designInfo);
        }

        if (initialData.designListInfo) {
            this.modulesService.addDesignListInfo(initialData.designListInfo);
        }

        if (initialData.importDesignProvider) {
            this.modulesService.addImportDesignProvider(initialData.importDesignProvider);
        }

        if (initialData.abpInfo) {
            this.modulesService.addAbpInfo(initialData.abpInfo);
        }

        if (initialData.integrationsInfo) {
            this.modulesService.addIntegrationsInfo(initialData.integrationsInfo);
        }

        if (initialData.favoritesInfo) {
            this.modulesService.addFavoritesInfo(initialData.favoritesInfo);
        }

        if (initialData.trackingInfo) {
            this.modulesService.addTrackingInfo(initialData.trackingInfo);
        }

        if (initialData.designManagement) {
            this.modulesService.addDesignManagementInfo(initialData.designManagement);
        }
    }

    public async onModulePreInit(preInitialDataOrFunction: IModulePreInitialData | (() => IModulePreInitialData | Promise<IModulePreInitialData> | null | undefined), moduleKey: string) {
        try {
            const preInitialData = await (isFunction(preInitialDataOrFunction) ? Promise.resolve(preInitialDataOrFunction()) : Promise.resolve(preInitialDataOrFunction));
            if (preInitialData != null) {

                if (preInitialData.authenticationRequiredServices) {
                    this.modulesService.addAuthenticationRequiredServices(preInitialData.authenticationRequiredServices);
                }

                if (preInitialData.serviceNameMapping) {
                    this.modulesService.addServiceNameMappings(preInitialData.serviceNameMapping);
                }

                if (preInitialData.importFileExtensions) {
                    this.modulesService.addImportFileExtensions(preInitialData.importFileExtensions);
                }

                if (preInitialData.userSettingsInfo) {
                    this.modulesService.addUserSettingsInfo(preInitialData.userSettingsInfo);
                }
                // TODO: BUDQBP-36171 remove once all modules are deployed!
                else if (!stringNullOrEmpty(moduleKey) && moduleKey === 'pe-ui-pe') {
                    registerUserSettingsInfoPe(this.modulesService);
                }
                else if (!stringNullOrEmpty(moduleKey) && moduleKey === 'pe-ui-c2c') {
                    registerUserSettingsInfoC2C(this.modulesService);
                }

                if (preInitialData.moduleVersionInfoProvider) {
                    this.modulesService.addModuleVersionInfoProvider(preInitialData.moduleVersionInfoProvider);
                }

                if (preInitialData.initialDataLoadedEvent) {
                    this.modulesService.addInitialDataLoadedEvent(preInitialData.initialDataLoadedEvent);
                }
            }
        }
        finally {
            // mark the module as loaded
            window.modules.find(x => x.name == moduleKey).isLoaded = true;

            // if all the modules are loaded continue the page load
            // TODO TEAM: fix pe-ui-common and pe-ui-decking which for some reason are loaded differently
            if (window.modules.filter(x => x.name != "pe-ui-common" && x.name != 'pe-ui-decking').every(x => x.isLoaded)) {
                this.modulesService.markModulesLoaded();
            }
        }
    }

    public logout() {
        this.authenticationService.logout();
    }

    /**
     * @deprecated backwards compatibility, removed with C2C-10693
     */
    public get peSupportsLoadingInitialData() {
        return FeaturesHelper.isPeModuleLoaded() && FeaturesHelper.PeSupports('loadingInitialData');
    }
}
