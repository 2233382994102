import { Component } from '@angular/core';

import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
    constructor(
        private readonly localizationService: LocalizationService
    ) { }

    public get title1() {
        return this.localizationService.translationsLoaded
            ? this.localizationService.getLocalizedString('Agito.Hilti.Profis3.GeneralError.Title1', LocalizationService.PBrB)
            : 'SORRY, THERE IS AN ERROR';
    }

    public get message() {
        return this.localizationService.translationsLoaded
            ? this.localizationService.getLocalizedString('Agito.Hilti.Profis3.GeneralError.Content', LocalizationService.PBrB)
            : '<p>It appears a critical error appeared on our side. We apologize and ensure you that we are working on it. We will be back as soon as possible. Therefore please try again in a moment or contact us in case you have any questions or need help. <br> profis@hilti.com</p>';
    }
}
